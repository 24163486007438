<template>
  <div class="wrap">
    <header class="x-page-title">
      <h3>藏品发行</h3>
    </header>

    <main class="x-main">
      <!-- 标题类型 -->
      <el-card class="mb30">
        <div slot="header">
          <span>基本信息：</span>
        </div>
        <el-form
          :model="form"
          label-width="120px"
          :rules="rules"
          ref="myForm"
          required
        >
          <el-form-item label="藏品名称：" prop="NFTName">
            <el-input
              placeholder="请输入藏品名称"
              v-model="form.NFTName"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="8"
              ><el-form-item label="所属品牌：" prop="brandID">
                <el-select
                  placeholder="请选择所属品牌"
                  v-model="form.brandID"
                  @change="doCollectionID"
                >
                  <el-option
                    v-for="(item, index) in optionsBrand"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select> </el-form-item></el-col
            ><el-col :span="8"
              ><el-form-item label="藏品集：" prop="collectionID">
                <el-select
                  placeholder="请选择藏品集"
                  v-model="form.collectionID"
                >
                  <el-option
                    v-for="(item, index) in optionsCollection"
                    :key="index"
                    :label="item.collectionName"
                    :value="item.id"
                  ></el-option>
                </el-select> </el-form-item></el-col
            ><el-col :span="8">
              <el-form-item label="藏品类型：" prop="NFTType">
                <el-select
                  placeholder="请选择藏品类型"
                  v-model="form.NFTType"
                  @change="doNFTTypeChange"
                >
                  <el-option label="图片" value="1"></el-option>
                  <el-option label="音频" value="2"></el-option>
                  <el-option label="视频" value="3"></el-option>
                  <el-option label="3D模型" value="4"></el-option>
                  <el-option label="盲盒" value="5"></el-option>
                </el-select>
              </el-form-item> </el-col
          ></el-row>
          <el-form-item label="序号开始编号:" prop="codeStart">
            <el-input
              v-model="form.codeStart"
              type="number"
              placeholder="请输入序号开始编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="发行数量：" prop="totalCount">
            <el-input
              placeholder="请输入发行数量"
              v-model="form.totalCount"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品描述：" prop="describtion">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入商品描述"
              v-model="form.describtion"
            ></el-input>
          </el-form-item>
          <el-form-item label="T+N设置：" prop="transferDays">
            <el-input
              type="number"
              placeholder="请输入T+N设置"
              v-model="form.transferDays"
            ></el-input>
          </el-form-item>
          <el-form-item label="藏品/盲盒标签：">
            <el-tag
              :key="index"
              v-for="(tag, index) in labelTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag.name }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else-if="labelTags.length < 3 && inputVisible == false"
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ New Tag</el-button
            >
            <span class="font-warning">（最多可添加不超过3个标签）</span>
          </el-form-item>
          <el-form-item
            label="设置奖品："
            v-if="form.NFTType === '5'"
            prop="prize"
          >
            <el-button
              type="primary"
              size="mini"
              round
              class="prize"
              @click="doOpen('1')"
              >添加盲盒</el-button
            >
            <el-table :data="form.prize" stripe style="width: 100%">
              <el-table-column prop="NFTID" label="藏品" width="220">
                <template slot-scope="scope">
                  <el-select
                    placeholder="请选择合成藏品"
                    v-model="scope.row.NFTID"
                    disabled
                  >
                    <el-option
                      v-for="(item, index) in optionsNft"
                      :key="index"
                      :label="item.NFTName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                prop="ceiling"
                label="数量"
                width="420"
                align="center"
              >
              </el-table-column>
              <el-table-column label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <div class="flex-box-around">
                    <el-link
                      :underline="false"
                      type="primary"
                      @click="doDeletePrize(scope.row)"
                      >删除</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="藏品特权：">
            <el-button
              type="primary"
              size="mini"
              round
              class="rights"
              @click="doOpen('2')"
              >添加特权</el-button
            >
            <el-table :data="form.rights" stripe style="width: 100%">
              <el-table-column prop="name" label="特权名称" width="220">
              </el-table-column>
              <el-table-column prop="desc" label="特权描述" width="420">
              </el-table-column>
              <el-table-column prop="endDate" label="特权截止日期" width="220">
              </el-table-column>
              <el-table-column label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <div class="flex-box-around">
                    <el-link
                      :underline="false"
                      type="primary"
                      @click="doDeleteRight(scope.row)"
                      >删除</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>

          <el-row>
            <el-col :span="12">
              <el-form-item label="藏品图片：" prop="license">
                <el-upload
                  class="x-single-upload"
                  drag
                  ref="upload"
                  action="#"
                  name="file"
                  accept=".jpg, .jpeg, .png,.gif"
                  :show-file-list="false"
                  :file-list="licenseList"
                  :on-change="setLicenseList"
                  :before-upload="beforeLicenseUpload"
                  :http-request="doLicenseUpload"
                >
                  <el-image
                    v-if="form.license"
                    style="width: 360px; height: 180px"
                    :src="form.license"
                    fit="contain"
                  ></el-image>
                  <div v-else>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    （支持PNG、JPG或Gif格式图片，图片大小不超过50M）
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12"
              ><el-form-item label="藏品缩略图：" prop="NFTThumbnailUrl">
                <el-upload
                  class="x-single-upload"
                  drag
                  ref="upload"
                  action="#"
                  name="file"
                  accept=".jpg, .jpeg, .png,.gif"
                  :show-file-list="false"
                  :file-list="NFTThumbnailUrlList"
                  :on-change="setNFTThumbnailUrlList"
                  :before-upload="beforeLicenseUpload"
                  :http-request="doNFTThumbnailUrlUpload"
                >
                  <el-image
                    v-if="form.NFTThumbnailUrl"
                    style="width: 360px; height: 180px"
                    :src="form.NFTThumbnailUrl"
                    fit="contain"
                  ></el-image>
                  <div v-else>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    （支持PNG、JPG或Gif格式图片，图片大小不超过50M）
                  </div>
                </el-upload>
              </el-form-item></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="音频试听文件："
                prop="NFTShortUrl"
                v-if="form.NFTType === '2'"
              >
                <el-upload
                  class="x-single-upload"
                  drag
                  ref="upload"
                  action="#"
                  name="file"
                  accept=".mp3"
                  :show-file-list="false"
                  :file-list="NFTShortUrlList"
                  :on-change="setNFTShortUrlList"
                  :before-upload="beforeAudioUpload"
                  :http-request="doNFTShortUrlUpload"
                >
                  <div v-if="form.NFTShortUrl">
                    <span>已上传文件（音频包），点击可试听</span>
                    <audio controls>
                      <source :src="form.NFTShortUrl" type="audio/mpeg" />
                    </audio>
                  </div>
                  <div v-else>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    （支持MP3格式的文件，文件大小不超过50M）
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="视频试看文件："
                prop="NFTShortUrl"
                v-if="form.NFTType === '3'"
              >
                <el-upload
                  class="x-single-upload"
                  drag
                  ref="upload"
                  action="#"
                  name="file"
                  accept=".mp4"
                  :show-file-list="false"
                  :file-list="NFTShortUrlList"
                  :on-change="setNFTShortUrlList"
                  :before-upload="beforeVideoUpload"
                  :http-request="doNFTShortUrlUpload"
                >
                  <video
                    v-if="form.NFTShortUrl"
                    style="width: 360px; height: 180px"
                    :src="form.NFTShortUrl"
                    fit="contain"
                    controls
                  ></video>
                  <div v-else>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    （支持MP4格式的文件，文件大小不超过50M）
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="藏品原图："
                prop="NFTHDUrl"
                v-if="form.NFTType === '1' || form.NFTType === '5'"
              >
                <el-upload
                  class="x-single-upload"
                  drag
                  ref="upload"
                  action="#"
                  name="file"
                  accept=".jpg, .jpeg, .png,.gif"
                  :show-file-list="false"
                  :file-list="NFTHDUrlList"
                  :on-change="setNFTHDUrlList"
                  :before-upload="beforeLicenseUpload"
                  :http-request="doNFTHDUrlUpload"
                >
                  <el-image
                    v-if="form.NFTHDUrl"
                    style="width: 360px; height: 180px"
                    :src="form.NFTHDUrl"
                    fit="contain"
                  ></el-image>
                  <div v-else>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    （支持PNG、JPG或Gif格式图片，图片大小不超50M）
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="藏品音频完整文件："
                prop="NFTHDUrl"
                v-else-if="form.NFTType === '2'"
              >
                <el-upload
                  class="x-single-upload"
                  drag
                  ref="upload"
                  action="#"
                  name="file"
                  accept=".mp3"
                  :show-file-list="false"
                  :file-list="NFTHDUrlList"
                  :on-change="setNFTHDUrlList"
                  :before-upload="beforeAudioUpload"
                  :http-request="doNFTHDUrlUpload"
                  ><div v-if="form.NFTHDUrl">
                    <span>已上传文件（音频包），点击可试听</span>
                    <audio controls>
                      <source :src="form.NFTHDUrl" type="audio/mpeg" />
                    </audio>
                  </div>
                  <div v-else>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    （支持MP3格式的文件，文件大小不超过50M）
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="藏品视频完整文件："
                prop="NFTHDUrl"
                v-else-if="form.NFTType === '3'"
              >
                <el-upload
                  class="x-single-upload"
                  drag
                  ref="upload"
                  action="#"
                  name="file"
                  accept=".mp4"
                  :show-file-list="false"
                  :file-list="NFTHDUrlList"
                  :on-change="setNFTHDUrlList"
                  :before-upload="beforeVideoUpload"
                  :http-request="doNFTHDUrlUpload"
                >
                  <video
                    v-if="form.NFTHDUrl"
                    style="width: 360px; height: 180px"
                    :src="form.NFTHDUrl"
                    fit="contain"
                    controls
                  ></video>
                  <div v-else>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    （支持mp4格式的文件，文件大小不超过50M）
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="3D模型(.zip)文件："
                prop="NFTHDUrl"
                v-if="form.NFTType === '4'"
              >
                <el-upload
                  class="x-single-upload"
                  drag
                  ref="upload"
                  action="#"
                  name="file"
                  accept=".zip,.obj"
                  :show-file-list="false"
                  :file-list="NFTHDUrlList"
                  :on-change="setNFTHDUrlList"
                  :before-upload="beforeMtlUpload"
                  :http-request="do3dUpload"
                >
                  <a
                    class="font-primary"
                    v-if="form.NFTHDUrl"
                    :href="form.NFTHDUrl"
                    target="_blank"
                    >已上传文件（模型包），点击可下载</a
                  >
                  <div v-else>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </div>
                  <div class="el-upload__tip" slot="tip">
                    （支持.obj,.zip格式的文件，文件大小不超过100M）
                  </div>
                </el-upload>
              </el-form-item>
            </el-col></el-row
          >
          <el-form-item label="藏品上架海报" prop="treeImg">
            <el-upload
              class="upload-demo"
              action="#"
              :limit="5"
              :on-remove="handleRemove"
              list-type="picture"
              :before-upload="beforeLicenseUpload"
              :on-change="setTreeImgList"
              :http-request="dotreeImgUpload"
              :on-exceed="exceed"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip" style="color: #909399">
                (支持PNG和JPG格式图片，图片大小不超过15M,最多上传五张海报)
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-row type="flex" justify="center" class="mt10">
          <el-col :span="6">
            <el-button type="primary" @click="doValidate">立即发行</el-button>
            <el-button @click="goBack">返回上级页面</el-button>
          </el-col>
        </el-row>
      </el-card>
    </main>
    <!-- 添加信息面板 -->
    <right-panel
      :dialogShow.sync="dialogShow"
      :views="view"
      ref="appPanel"
      @updata="acceptData"
    ></right-panel>
  </div>
</template>

<script>
import { FileUpload } from "@/api/common";
import { BrandQuery } from "@/api/module/brand";
import { CollectionQuery } from "@/api/module/collection";
import { NftCreate, ModelFilesUpload } from "@/api/module/nft";
import { NftQuery } from "@/api/module/nft";

let Brarules = {
  treeImg: [
    {
      required: false,
      message: "请上传藏品上架海报",
      trigger: "change",
    },
  ],
  transferDays: [
    {
      required: false,
      transform(value) {
        return value.toString();
      },
      pattern: "^[1-9]*[0-9][0-9]*$",
      message: "必填且为正实数(可为0)",
      trigger: "blur",
    },
  ],
  NFTHDUrl: [
    {
      required: true,
      message: "请上传藏品原图",
      trigger: "change",
    },
  ],
  codeStart: [
    {
      required: true,
      message: "请输入序号",
      trigger: "blur",
    },
  ],
  NFTThumbnailUrl: [
    {
      required: true,
      message: "请上传藏品缩略图",
      trigger: "change",
    },
  ],
  NFTName: [
    {
      required: true,
      message: "请输入藏品名称",
      trigger: "blur",
    },
  ],
  describtion: [
    {
      required: true,
      message: "请输入商品描述",
      trigger: "blur",
    },
  ],
  totalCount: [
    {
      required: true,
      message: "请输入发行数量",
      trigger: "blur",
    },
  ],
  license: [
    {
      required: true,
      message: "请上传藏品图",
      trigger: "change",
    },
  ],
  brandID: [
    {
      required: true,
      message: "请选择所属品牌",
      trigger: "change",
    },
  ],
  collectionID: [
    {
      required: true,
      message: "请选择藏品集",
      trigger: "change",
    },
  ],
  NFTType: [
    {
      required: true,
      message: "请选择藏品集",
      trigger: "change",
    },
  ],
};
export default {
  name: "NftCreate",
  components: {
    rightPanel: () => import("./components/RightPanel"),
  },
  data() {
    return {
      treeImgList: [],
      optionsNft: [],
      inputVisible: false,
      inputValue: "",
      labelTags: [],
      dialogShow: false,
      optionsBrand: [],
      optionsCollection: [],
      NFTHDUrlList: [],
      NFTThumbnailUrlList: [],
      NFTShortUrlList: [],
      licenseList: [],
      view: "",
      form: {
        treeImg: [],
        brandID: "",
        transferDays: "",
        collectionID: "",
        license: "",
        describtion: "",
        NFTName: "",
        totalCount: "",
        NFTThumbnailUrl: "",
        NFTHDUrl: "",
        NFTType: "",
        NFTShortUrl: "",
        codeStart: "",
        rights: [],
        prize: [],
      },
      rules: Brarules,
    };
  },
  created() {
    this.getPackage();
    this.getNft();
  },
  updated() {
    this.$nextTick(() => {
      // this.$refs.saveTagInput.$el.style.width = 600 + "px";
      console.log(this.$refs.saveTagInput.$el.offsetHeight);
      //  仅在整个视图都被重新渲染之后才会运行的代码
    });
  },

  methods: {
    async dotreeImgUpload(param) {
      let oData = new FormData();
      const oFileList = this.treeImgList;
      oFileList.forEach(() => {
        oData.append("file", param.file);
      });
      const r = await FileUpload(oData);
      this.$refs["myForm"].clearValidate("treeImg");
      const rs = { ...r.data.file, uid: param.file.uid };
      this.form.treeImg.push(rs);
    },
    // 选择茶树品种图
    setTreeImgList(file, fileList) {
      this.treeImgList = fileList;
    },
    handleRemove(file, fileList) {
      this.form.treeImg = this.form.treeImg.filter((val, index) => {
        return val.uid != file.uid;
      });
    },
    getNft() {
      let query = `?pagination=false&page=1&pageSize=10&status=3`;
      NftQuery(query).then((r) => {
        this.optionsNft = r.list;
      });
    },
    handleInputConfirm() {
      let inputValue = {
        name: this.inputValue,
      };
      if (inputValue.name) {
        this.labelTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleClose(tag) {
      this.labelTags.splice(this.labelTags.indexOf(tag), 1);
    },
    do3dUpload() {
      let oData = new FormData();
      const oFileList = this.NFTHDUrlList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      ModelFilesUpload(oData).then((r) => {
        this.form.NFTHDUrl = r.data.file.url;
        this.form.NFTShortUrl = r.data.file.objUrl;
        this.$refs["myForm"].clearValidate("NFTHDUrl");
      });
      this.NFTHDUrlList = [];
    },
    doDeletePrize(val) {
      const tags = this.form.prize.filter(
        (item) => item.NTFID !== val.NTFID || item.ceiling !== val.ceiling
      );
      this.form.prize = tags;
    },
    doDeleteRight(val) {
      const tags = this.form.rights.filter(
        (item) =>
          item.name !== val.name ||
          item.endDate !== val.endDate ||
          item.desc !== val.desc
      );
      this.form.rights = tags;
    },
    acceptData(val) {
      if (this.view == "1") {
        this.form.prize.push(val);
      } else {
        this.form.rights.push(val);
      }
    },
    exceed() {
      this.$message({
        type: "info",
        message: "只能允许上传五张海报",
        duration: 1300,
      });
    },
    doOpen(view) {
      this.dialogShow = true;
      this.view = view;
    },
    setNFTShortUrlList(file, fileList) {
      this.NFTShortUrlList = fileList;
    },
    doNFTShortUrlUpload() {
      let oData = new FormData();
      const oFileList = this.NFTShortUrlList;
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.NFTShortUrl = r.data.file.url;
        this.$refs["myForm"].clearValidate("NFTShortUrl");
      });
      this.NFTShortUrlList = [];
    },
    doNFTTypeChange(val) {
      this.form.NFTHDUrl = "";
      this.form.NFTShortUrl = "";
      if (val == "2") {
        Brarules.NFTShortUrl = [
          {
            required: true,
            message: "请上传藏品试听音频",
            trigger: "change",
          },
        ];
        Brarules.NFTHDUrl = [
          {
            required: true,
            message: "请上传藏品完整音频",
            trigger: "change",
          },
        ];
      } else if (val == "3") {
        Brarules.NFTShortUrl = [
          {
            required: true,
            message: "请上传藏品试看视频",
            trigger: "change",
          },
        ];
        Brarules.NFTHDUrl = [
          {
            required: true,
            message: "请上传藏品完整视频",
            trigger: "change",
          },
        ];
      } else if (val == "4") {
        Brarules.NFTHDUrl = [
          {
            required: true,
            message: "请上传藏品3D模型(.obj,.zip)",
            trigger: "change",
          },
        ];
      } else if (val == "5") {
        Brarules.prize = [
          {
            required: true,
            message: "请设置藏品奖品及数量",
            trigger: "change",
          },
        ];
      }
    },
    async doCollectionID() {
      const rc = await CollectionQuery(
        `?pagination=false&current=1&pageSize=10&status=3&brandID=${this.form.brandID}`
      );
      this.optionsCollection = rc.list;
    },
    doNFTHDUrlUpload() {
      let oData = new FormData();
      const oFileList = this.NFTHDUrlList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.NFTHDUrl = r.data.file.url;
        this.$refs["myForm"].clearValidate("NFTHDUrl");
      });
      this.NFTHDUrlList = [];
    },
    setNFTHDUrlList(file, fileList) {
      this.NFTHDUrlList = fileList;
    },
    doNFTThumbnailUrlUpload() {
      let oData = new FormData();
      const oFileList = this.NFTThumbnailUrlList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.NFTThumbnailUrl = r.data.file.url;
        this.$refs["myForm"].clearValidate("NFTThumbnailUrl");
      });
      this.NFTThumbnailUrlList = [];
    },
    setNFTThumbnailUrlList(file, fileList) {
      this.NFTThumbnailUrlList = fileList;
    },
    // 获取选项
    async getPackage() {
      const rb = await BrandQuery(`?pagination=false&current=1&pageSize=10`);
      this.optionsBrand = rb.list;
    },
    doValidate() {
      let f = this.form;
      //   新增
      let oData = {
        brandID: f.brandID,
        NFTUrl: f.license,
        describtion: f.describtion,
        collectionID: f.collectionID,
        totalCount: Number(f.totalCount),
        NFTName: f.NFTName,
        NFTThumbnailUrl: f.NFTThumbnailUrl,
        NFTHDUrl: f.NFTHDUrl,
        NFTType: f.NFTType,
        NFTShortUrl: f.NFTShortUrl,
        codeStart: Number(f.codeStart),
        rights: f.rights,
        label: this.labelTags,
        transferDays: Number(f.transferDays),
        PosterUrl: f.treeImg,
        prize: f.prize,
      };
      this.$refs.myForm.validate(async (val) => {
        if (val) {
          const r = await NftCreate(oData);
          if (r.code != 0) return;
          if (r.code == 0) {
            this.$message({
              type: "success",
              message: "创建成功",
              duration: 1300,
              onClose: () => {
                this.goBack();
              },
            });
          }
        } else {
          //全局验证不通过
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    // 选择轮播图
    setLicenseList(file, fileList) {
      this.licenseList = fileList;
    },
    beforeMtlUpload(file) {
      const sizeLimit = file.size / 1024 / 1024 < 100;
      if (!sizeLimit) {
        this.$message.error("上传模型文件大小不能超过 100MB!");
        return false;
      }
      return true;
    },
    // 限制轮播图大小与格式
    beforeVideoUpload(file) {
      console.log(file);
      if (["mp4"].indexOf(file.type.split("/")[1]) < 0) {
        this.$message.error("上传视频只能是mp4格式!");
        return false;
      }
      const sizeLimit = file.size / 1024 / 1024 < 50;
      if (!sizeLimit) {
        this.$message.error("上传视频文件大小不能超过 50MB!");
        return false;
      }
      return true;
    },
    // 限制轮播图大小与格式
    beforeAudioUpload(file) {
      console.log(file);
      if (["mpeg"].indexOf(file.type.split("/")[1]) < 0) {
        this.$message.error("上传音频只能是MP3格式!");
        return false;
      }
      const sizeLimit = file.size / 1024 / 1024 < 50;
      if (!sizeLimit) {
        this.$message.error("上传音频文件大小不能超过 50MB!");
        return false;
      }
      return true;
    },

    // 限制音频大小与格式
    beforeLicenseUpload(file) {
      if (["jpeg", "png", "jpg", "gif"].indexOf(file.type.split("/")[1]) < 0) {
        this.$message.error("上传图片只能是JPG、PNG或gif格式!");
        return false;
      }
      const sizeLimit = file.size / 1024 / 1024 < 50;
      if (!sizeLimit) {
        this.$message.error("上传图片大小不能超过 50MB!");
        return false;
      }
      return true;
    },
    // 上传轮播图
    doLicenseUpload() {
      let oData = new FormData();
      const oFileList = this.licenseList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.license = r.data.file.url;
        this.$refs["myForm"].clearValidate("license");
      });
      this.licenseList = [];
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tag + .el-tag {
  margin-left: 10px;
}
/deep/.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
/deep/.input-new-tag {
  width: 200px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>